import * as React from "react";
import styled, { css } from "styled-components";
import { sanitizeUrl } from "../../utils/urls";
import { ComponentProps } from "../types";

type Props = ComponentProps & {
  /** Children to display to the right of the context */
  children?: React.ReactElement;
  /** Callback fired when the widget is clicked */
  onMouseDown?: React.MouseEventHandler<HTMLAnchorElement>;
};

export default function Audio(props: Props) {
  const { isSelected, node, children } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  const style: React.CSSProperties = {
    width: "100%",
    height: 50,
    pointerEvents: "all",
  };

  return (
    <div contentEditable={false} ref={ref}>
      <AudioWrapper
        className={isSelected ? "ProseMirror-selectednode audio" : "audio"}
        style={style}
      >
        <StyledAudio
          src={sanitizeUrl(node.attrs.src)}
          title={node.attrs.title}
          style={style}
          controls
        />
      </AudioWrapper>
      {children}
    </div>
  );
}

export const audioStyle = css`
  width: 100%;
  max-width: 100%;
  height: 50px;
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.text} !important;
  margin: -2px;
  padding: 2px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px ${(props) => props.theme.divider};
`;

const StyledAudio = styled.audio`
  ${audioStyle}
`;

const AudioWrapper = styled.div`
  line-height: 0;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  cursor: default;
  border-radius: 8px;
  user-select: none;
  max-width: 100%;
  overflow: hidden;

  transition-property: width;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;

  audio {
    transition-property: width;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    cursor: pointer !important;
    opacity: 1;
  }
`;
